import React from "react";

class Imperfectlovers extends React.Component {
  sendDataToMenu = () => {
    const data = "imperfect";
    // Call the callback function passed from Menu
    this.props.onDataFromMenu(data);
  };

  componentDidMount() {
    this.sendDataToMenu();
    document.body.style.backgroundColor = "rgb(202,209,232)";
    document.title = "Imperfect Lovers";
  }

  render() {
    return <div></div>;
  }
}
export default Imperfectlovers;
