import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="w3-center w3-light-grey w3-padding-16">
        <p>
          adapted from{" "}
          <a
            href="https://www.w3schools.com/w3css/default.asp"
            title="W3.CSS"
            rel="noreferrer"
            target="_blank"
            className="w3-hover-text-blue"
          >
            w3.css
          </a>
        </p>
      </footer>
    );
  }
}

export default Footer;
