import React from "react";
import Footer from "./footer";

import Webconnection from "../web3";
import { detectAndSetWeb3 } from "../web3";
import { getWebcall } from "../web3";

import { imlovABI } from "../imlov";
import { imlovaddress } from "../imlov";
import { coopABI } from "../cooperator";
import { coopaddress } from "../cooperator";

let web3;

class Moreinfo extends React.Component {
  //state vars for web3
  state = { web3: null, accounts: null, imlov: null, cooperator: null };

  // var to show or not show menu
  sendDataToMenu = () => {
    const data = true;
    // Call the callback function passed from Menu
    this.props.onDataFromMenu(data);
  };

  //connect contracts via how ever web is connected
  async contracts() {
    const imlov = new this.state.web3.eth.Contract(imlovABI, imlovaddress);
    const cooperator = new this.state.web3.eth.Contract(coopABI, coopaddress);
    this.setState({ imlov });
    this.setState({ cooperator });
  }

  //connect metamask
  onConnectButton = async (event) => {
    const connect = await window.ethereum.request({ method: "eth_accounts" });

    if (connect.length === 0) {
      await getWebcall();
      web3 = await detectAndSetWeb3();
      this.setState({ web3 }, () => {
        // Callback function to insure state/contract before transact
        this.contracts();
      });
      const accounts = await web3.eth.getAccounts();
      this.setState({ accounts });
    }
  };

  // on load
  async componentDidMount() {
    //menu or not?
    this.sendDataToMenu();

    //title of page
    document.title = "Imperfect Lovers - More Info";

    //connect to web3 - either infura or metamasl
    if (!this.state.web3) {
      web3 = await detectAndSetWeb3();

      const accounts = await web3.eth.getAccounts();
      this.setState({ accounts });

      this.setState({ web3 }, () => {
        this.contracts();
      });
    }
  }

  render() {
    return (
      <div>
        <Webconnection />

        <header
          className="w3-display-container w3-content"
          style={{ maxWidth: "100%", minWidth: "500px", marginTop: "50px" }}
          id="home"
        >
          <a href="/">
            <img
              className="w3-image"
              src="./images/imperfect-lovers.png"
              alt="cropped Imperfect Lovers"
            />
          </a>
        </header>

        <div
          className="w3-right"
          style={{ width: "230px", marginRight: "5px", marginTop: "-5px" }}
        >
          {this.state.accounts != 0 && (
            <button
              type="button"
              disabled
              className="connect
      w3-button w3-light-grey w3-section"
            >
              connected: {this.state.accounts}
            </button>
          )}

          {this.state.accounts == 0 && (
            <button
              className="connect
      w3-button w3-light-grey w3-section"
              onClick={this.onConnectButton}
            >
              connect wallet
            </button>
          )}
        </div>

        <div className="w3-content">
          <div className="w3-padding-32" id="about">
            <div className="w3-padding-large">
              <h1>More Info</h1>

              <p>
                <i>Imperfect Lovers</i> was inaugurated on February 23rd, 2024. I am working with a writer and a
                documentarian on releasing a review of, and more information
                about, its work. Check back here in the coming weeks for
                updates.
              </p>

              <p>

              The first three mints in this series - Imperfect Lovers, Lover 0, and Lover 1 - are dynamic and animated NFTs, which update with each trans-action. These are directly and continuously connected to their respective Smart Contracts, held/collected together and meant for institutional custodianship and <a href="https://imperfectlovers.art/exhibit-details">exhibition</a>. 

              All subsequent NFTs are static, daily portraits, freely available once - and only once - per day.<br />&lt;<a href="https://opensea.io/collection/imperfect-lovers/overview" target="_blank" >
                  View on OpenSea
                  </a>&gt;
              </p>

              <img
                src="https://i0.wp.com/nathanielstern.com/newsite/wp-content/uploads/nathaniel-new-1.jpg?w=1920&ssl=1"
                width="75%"
              />
              <span
                className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small"
                style={{ width: "75%" }}
              >
                Artist, Nathaniel Stern, in his studio.
              </span>
              <p>
                I have been making, teaching, and writing about digital art for
                more than 25 years. I like to work with technologies just as
                they hit the mainstream in a way where everyone is talking about
                them... but none of us really know about them. Here, I've
                flipped a libertarian and capitalist platform of crypto currency
                into a space for gifting and love.
              </p>
              <p>
                There's a long history to this work, and{" "}
                <a href="https://nathanielstern.art/">my work</a>, but one of
                the core predecessors (in addition to the FGT and On Kawara
                pieces I talk about on this site's main page) is my art work, {" "}
                <a href="https://nathanielstern.com/artwork/given-time/">
                  Given Time</a>, where two permanently logged-in Second Life avatars are each forever
                and only seen by and through the other. The video below, circa
                2013, gives more details.
              </p>
              <h3 className="w3-text-blue-grey">Given Time</h3>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/deub5AMgblk?si=p2RuZUtVD9MkxpR6"
                allowFullScreen
              ></iframe>
              <br /> <br />
              <h3 className="w3-text-blue-grey">In the News</h3>
              <p>coming soon...</p>
              
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default Moreinfo;
