import React from "react";
import Footer from "./footer";

class Admin extends React.Component {
  sendDataToMenu = () => {
    const data = true;
    // Call the callback function passed from Menu
    this.props.onDataFromMenu(data);
  };

  componentDidMount() {
    this.sendDataToMenu();

    document.title = "Imperfect Lovers - Admin";
  }

  render() {
    return (
      <div>
        <header
          className="w3-display-container w3-content"
          style={{ maxWidth: "100%", minWidth: "500px", marginTop: "50px" }}
          id="home"
        >
          <a href="/">
            <img
              className="w3-image"
              src="./images/imperfect-lovers.png"
              alt="cropped Imperfect Lovers"
            />
          </a>
        </header>

        <div className="w3-content">
          <div className="w3-padding-32" id="about">
            <div className="w3-padding-large">
              <h1>Admin</h1>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default Admin;
