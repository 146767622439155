import React from "react";

import Webconnection from "../web3";
import { detectAndSetWeb3 } from "../web3";

import { imlovABI } from "../imlov";
import { imlovaddress } from "../imlov";

class ImperfectloversSVG extends React.Component {
  state = {
    decodedSVG: "",
    newDecodedSVG: "",
    accounts: null,
    imlov: null,
  };

  async componentDidMount() {
    //title of page
    document.title = "Imperfect Lovers: Lover 0";

    const web3 = await detectAndSetWeb3();
    this.setState({ web3 }, async () => {
      // Callback function to insure state/contract before transact
      const imlov = new this.state.web3.eth.Contract(imlovABI, imlovaddress);
      this.setState({ imlov }, async () => {
        const tokenURI = await imlov.methods.tokenURI(1).call();
        const tokenURIStr = tokenURI.replace("data:application/json;utf8,", "");
        const encodedSVG = JSON.parse(tokenURIStr).image.replace(
          "data:image/svg+xml;base64,",
          ""
        );
        const decodedSVG = String(atob(encodedSVG));
        this.setState({ decodedSVG });
      });
    });

    // try test every 10 mins
    this.intervalId = setInterval(this.fetchOnChainValue, 300000); // Poll every 10 minutes
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  //the actual test
  fetchOnChainValue = async () => {
    try {
      // Fetch on-chain variable (e.g., using Web3.js)
      const tokenURI2 = await this.state.imlov.methods.tokenURI(1).call();
      const tokenURIStr2 = tokenURI2.replace("data:application/json;utf8,", "");
      const encodedSVG2 = JSON.parse(tokenURIStr2).image.replace(
        "data:image/svg+xml;base64,",
        ""
      );
      const newDecodedSVG = String(atob(encodedSVG2));

      // Compare with previous value
      if (newDecodedSVG !== this.state.decodedSVG) {
        // Update state and trigger re-render
        this.setState({
          newDecodedSVG: newDecodedSVG,
          decodedSVG: newDecodedSVG,
        });
      }
    } catch (error) {
      console.error("Error fetching on-chain value:", error);
    }
  };

  render() {
    return (
      <div>
        <Webconnection />
        <div
          dangerouslySetInnerHTML={{ __html: this.state.decodedSVG }}
          alt="Imperfect Lovers: Lover 0"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            left: "50%",
            top: "50%",
            overflow: "hidden",
            WebkitTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    );
  }
}
export default ImperfectloversSVG;
