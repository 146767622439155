import React from "react";

class Lover1 extends React.Component {
  sendDataToMenu = () => {
    const data = "lover1";
    // Call the callback function passed from Menu
    this.props.onDataFromMenu(data);
  };

  componentDidMount() {
    this.sendDataToMenu();
    document.body.style.backgroundColor = "rgb(202,209,232)";
    document.title = "Imperfect Lovers: Lover 1";
  }

  render() {
    return <div></div>;
  }
}
export default Lover1;
