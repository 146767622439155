import React from "react";
import Footer from "./footer";
//npx serve -s build
import Webconnection from "../web3";
import { detectAndSetWeb3 } from "../web3";
import { getWebcall } from "../web3";

import { imlovABI } from "../imlov";
import { imlovaddress } from "../imlov";
import { useState } from "react";

import { useEffect, useLayoutEffect } from "react";

import { gsap } from "gsap";
import CSSPlugin from "gsap/CSSPlugin";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger, useGSAP, CSSPlugin);

function View(props) {
  const C = CSSPlugin;
  const [decodedSVG, setDecodedSVG] = useState("");
  const [newDecodedSVG, setNewDecodedSVG] = useState("");
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [imlov, setImlov] = useState(null);
  var [intervalId, setIntervalId] = useState(null);
  var [intervalId2, setIntervalId2] = useState(null);

  const sendDataToMenu = () => {
    const data = true;
    // Call the callback function passed from Menu
    props.onDataFromMenu(data);
  };

  const onConnectButton = async (event) => {
    const connect = await window.ethereum.request({ method: "eth_accounts" });

    if (connect.length === 0) {
      await getWebcall();
      const web3Instance = await detectAndSetWeb3();
      setWeb3(web3Instance);
      const accountsList = await web3Instance.eth.getAccounts();
      setAccounts(accountsList);
      onMount();
    }
  };

  const onMount = async () => {
    document.title = "Imperfect Lovers - View";
    const web3Instance = await detectAndSetWeb3();
    const accountsList = await web3Instance.eth.getAccounts();
    setAccounts(accountsList);
    setWeb3(web3Instance);

    const imlovInstance = new web3Instance.eth.Contract(imlovABI, imlovaddress);
    setImlov(imlovInstance);

    const tokenURI = await imlovInstance.methods.tokenURI(0).call();
    const tokenURIStr = tokenURI.replace("data:application/json;utf8,", "");
    const encodedSVG = JSON.parse(tokenURIStr).image.replace(
      "data:image/svg+xml;base64,",
      ""
    );
    setDecodedSVG(String(atob(encodedSVG)));
  };

  useEffect(() => {
    sendDataToMenu();
    document.title = "Imperfect Lovers by Nathaniel Stern";
    onMount();

    const fetchOnChainValue = async () => {
      try {
        const tokenURI2 = await imlov.methods.tokenURI(0).call();
        const tokenURIStr2 = tokenURI2.replace(
          "data:application/json;utf8,",
          ""
        );
        const encodedSVG2 = JSON.parse(tokenURIStr2).image.replace(
          "data:image/svg+xml;base64,",
          ""
        );
        const newDecodedSVGtemp = String(atob(encodedSVG2));

        if (newDecodedSVGtemp !== decodedSVG) {
          setNewDecodedSVG(newDecodedSVGtemp);
          setDecodedSVG(newDecodedSVGtemp);
        }
      } catch (error) {
        console.error("Error fetching on-chain value:", error);
      }
    };

    const interval = setInterval(fetchOnChainValue, 300000); // Poll every 10 minutes
    setIntervalId(interval);

    const interval2 = setInterval(() => {
      if (!decodedSVG) {
        onMount();
        clearInterval(interval2);
      }
    }, 3000);
    setIntervalId2(interval2);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, [decodedSVG]);

  //do the push thing here and then use the tostring?

  return (
    <div>
      <Webconnection />

      <header
        className="w3-display-container w3-content"
        style={{ maxWidth: "100%", minWidth: "500px", marginTop: "50px" }}
        id="home"
      >
        <a href="/">
          <img
            className="w3-image"
            src="./images/imperfect-lovers.png"
            alt="cropped Imperfect Lovers"
          />
        </a>
      </header>

      <div
        className="w3-right"
        style={{ width: "230px", marginRight: "5px", marginTop: "-5px" }}
      >
        {accounts != 0 && (
          <button
            type="button"
            disabled
            className="connect
      w3-button w3-light-grey w3-section"
          >
            connected: {accounts}
          </button>
        )}

        {accounts == 0 && (
          <button
            className="connect
      w3-button w3-light-grey w3-section"
            onClick={onConnectButton}
          >
            connect wallet
          </button>
        )}
      </div>

      <div className="w3-content">
        <div className="w3-padding-32" id="about">
          <div className="w3-padding-large">
            <h1>
              View
              <br />{" "}
            </h1>
            <br />

            <p> The <i>Imperfect Lovers</i> can be viewed together in one browser / wide screen, separately across two browsers 
            on one screen, or each individually on their own screen (preferred). For viewing and installation, 
              open either Lover (or both together) via the links and live images below.
              </p>
            <img src="./images/corner-imperfect.jpg" width="100%" />


           <p>
              Please open and view Lovers  in fullscreen mode of your browser, without the toolbar or bookmark bar
              showing (settings are under "view" in your browser). The Lovers
              will always automatically update with each new on-chain
              transaction.
            </p>
            
            <p>
              <div style={{ position: "relative" }}>
                <iframe
                  style={{ aspectRatio: "1 / 1", width: "50%", height: "auto" }}
                  frameBorder="0"
                  src="./lover-0"
                  title="Lover 0"
                ></iframe>

                <a
                  href="./lover-0"
                  target="blank"
                  style={{
                    aspectRatio: "1 / 1",
                    width: "50%",
                    height: "auto",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    display: "inlineBlock",
                    zIndex: "5",
                  }}
                ></a>
              </div>

              <span
                className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small"
                style={{ marginTop: "-1vh", width: "50%" }}
              >
                <br /> Live Lover 0.{" "}
                <a href="/lover-0" target="blank">
                  View only live Lover 0 in a browser window.
                </a>
              </span>
            </p>


            <p>
              <div style={{ position: "relative" }}>
                <iframe
                  style={{ aspectRatio: "1 / 1", width: "50%", height: "auto" }}
                  frameBorder="0"
                  src="./lover-1"
                  title="Lover 1"
                ></iframe>

                <a
                  href="./lover-1"
                  target="blank"
                  style={{
                    aspectRatio: "1 / 1",
                    width: "50%",
                    height: "auto",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    display: "inlineBlock",
                    zIndex: "5",
                  }}
                ></a>
              </div>

              <span
                className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small"
                style={{ marginTop: "-1vh", width: "50%" }}
              >
                <br /> Live Lover 1.{" "}
                <a href="/lover-1" target="blank">
                  View only live Lover 1 in a browser window.
                </a>
              </span>
            </p>

            <div
              className="w3-display-container w3-light-grey"
              style={{ width: "100%" }}
            >
              <a href="/imperfect-lovers-live" target="blank">
                <div
                  dangerouslySetInnerHTML={{ __html: decodedSVG }}
                  alt="Imperfect Lovers SVG"
                />
              </a>

              <span className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small">
                The two, live, fully on-chain Lovers.{" "}
                <a href="/imperfect-lovers-live" target="blank">
                  View both the live Lovers fully in a browser window.
                </a>
              </span>
            </div>



            <ul>
             
              <li>
                <a href="./lover-0" target="_blank" rel="noopener noreferrer">
                  Lover 0
                </a>
              </li>
              <li>
                <a href="./lover-1" target="_blank" rel="noopener noreferrer">
                  Lover 1
                </a>
              </li>
              <li>
                <a
                  href="./imperfect-lovers-live"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  both Imperfect Lovers
                </a>
              </li>
            </ul>
            
            You can also view the <a href="https://opensea.io/collection/imperfect-lovers/overview" target="_blank">entire / historical collection on OpenSea.</a>

          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default View;
