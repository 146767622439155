import React from "react";
import Menu from "./components/menu";
import ImperfectloversSVG from "./components/imperfect-lovers-SVG";
import Lover0SVG from "./components/lover0-SVG";
import Lover1SVG from "./components/lover1-SVG";

import { BrowserRouter as Router } from "react-router-dom";


class App extends React.Component {
  state = {
    ismenu: true,
    isImperfect: false,
    isLover0: false,
    isLover1: false,
  };

  menuVis = (data) => {
    if (data !== true) {
      this.setState({ ismenu: false });
    }
    if (data === "imperfect") {
      this.setState({ isImperfect: true });
    } else if (data === "lover0") {
      this.setState({ isLover0: true });
    } else if (data === "lover1") {
      this.setState({ isLover1: true });
    }
  };

  render() {
    return (
      <Router>
        <div>
          {this.state.ismenu ? <Menu onMenuVis={this.menuVis} /> : null}
          {this.state.isImperfect ? <ImperfectloversSVG /> : null}
          {this.state.isLover0 ? <Lover0SVG /> : null}
          {this.state.isLover1 ? <Lover1SVG /> : null}
        </div>
      </Router>
    );
  }
}
export default App;


