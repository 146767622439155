import React from "react";

class Lover0 extends React.Component {
  sendDataToMenu = () => {
    const data = "lover0";
    // Call the callback function passed from Menu
    this.props.onDataFromMenu(data);
  };

  componentDidMount() {
    this.sendDataToMenu();
    document.body.style.backgroundColor = "rgb(202,209,232)";
    document.title = "Imperfect Lovers: Lover 0";
  }

  render() {
    return <div></div>;
  }
}

export default Lover0;
