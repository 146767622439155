import React from "react";
import Footer from "./footer";
//npx serve -s build
import Webconnection from "../web3";
import { detectAndSetWeb3 } from "../web3";
import { getWebcall } from "../web3";

import { imlovABI } from "../imlov";
import { imlovaddress } from "../imlov";
import { useState } from "react";

import { useEffect, useLayoutEffect } from "react";

import { gsap } from "gsap";
import CSSPlugin from "gsap/CSSPlugin";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger, useGSAP, CSSPlugin);

function CuratorsStatement(props) {
  const C = CSSPlugin;
  const [decodedSVG, setDecodedSVG] = useState("");
  const [newDecodedSVG, setNewDecodedSVG] = useState("");
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [imlov, setImlov] = useState(null);
  var [intervalId, setIntervalId] = useState(null);
  var [intervalId2, setIntervalId2] = useState(null);

  const sendDataToMenu = () => {
    const data = true;
    // Call the callback function passed from Menu
    props.onDataFromMenu(data);
  };

  const onConnectButton = async (event) => {
    const connect = await window.ethereum.request({ method: "eth_accounts" });

    if (connect.length === 0) {
      await getWebcall();
      const web3Instance = await detectAndSetWeb3();
      setWeb3(web3Instance);
      const accountsList = await web3Instance.eth.getAccounts();
      setAccounts(accountsList);
      onMount();
    }
  };

  const onMount = async () => {

    document.title = "Imperfect Lovers - Curators Statement";
    const web3Instance = await detectAndSetWeb3();
    const accountsList = await web3Instance.eth.getAccounts();
    setAccounts(accountsList);
    setWeb3(web3Instance);

    const imlovInstance = new web3Instance.eth.Contract(imlovABI, imlovaddress);
    setImlov(imlovInstance);

    const tokenURI = await imlovInstance.methods.tokenURI(0).call();
    const tokenURIStr = tokenURI.replace("data:application/json;utf8,", "");
    const encodedSVG = JSON.parse(tokenURIStr).image.replace(
      "data:image/svg+xml;base64,",
      ""
    );
    setDecodedSVG(String(atob(encodedSVG)));
  };

  useEffect(() => {
    sendDataToMenu();
    document.title = "Imperfect Lovers by Nathaniel Stern";
    onMount();

    const fetchOnChainValue = async () => {
      try {
        const tokenURI2 = await imlov.methods.tokenURI(0).call();
        const tokenURIStr2 = tokenURI2.replace(
          "data:application/json;utf8,",
          ""
        );
        const encodedSVG2 = JSON.parse(tokenURIStr2).image.replace(
          "data:image/svg+xml;base64,",
          ""
        );
        const newDecodedSVGtemp = String(atob(encodedSVG2));

        if (newDecodedSVGtemp !== decodedSVG) {
          setNewDecodedSVG(newDecodedSVGtemp);
          setDecodedSVG(newDecodedSVGtemp);
        }
      } catch (error) {
        console.error("Error fetching on-chain value:", error);
      }
    };

    const interval = setInterval(fetchOnChainValue, 300000); // Poll every 10 minutes
    setIntervalId(interval);

    const interval2 = setInterval(() => {
      if (!decodedSVG) {
        onMount();
        clearInterval(interval2);
      }
    }, 3000);
    setIntervalId2(interval2);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, [decodedSVG]);

  
  

  //do the push thing here and then use the tostring?

  return (
    <div>
      <Webconnection />

      <header
          className="w3-display-container w3-content"
          style={{ maxWidth: "100%", minWidth: "500px", marginTop: "50px" }}
          id="home"
        >
          <a href="/">
            <img
              className="w3-image"
              src="./images/imperfect-lovers.png"
              alt="cropped Imperfect Lovers"
            />
          </a>
        </header>


      <div
        className="w3-right"
        style={{ width: "230px", marginRight: "5px", marginTop: "-5px" }}
      >
        {accounts != 0 && (
          <button
            type="button"
            disabled
            className="connect
      w3-button w3-light-grey w3-section"
          >
            connected: {accounts}
          </button>
        )}

        {accounts == 0 && (
          <button
            className="connect
      w3-button w3-light-grey w3-section"
            onClick={onConnectButton}
          >
            connect wallet
          </button>
        )}
      </div>

 

      <div
        className="w3-content"
      >
        <div className="w3-padding-32" id="about">
          <div className="w3-padding-large">
            
          <h1>Curators Statement<br /> </h1>
          <br /> 

          <img src="./images/2up-phones.jpg" width="100%" />

          <p>
                <i>Imperfect Lovers</i> is conceived as both a virtual and
                actual work, where the on-chain Smart Contracts are core &#8212;  and
                allow for new and different interfaces in the future&#8212;  while
                viewership is meant to be a physical experience in and around screens and media, viscerally shared in the real world.
              </p>


            <p>
              Regarding new interfaces for the Lovers and their Daily Displays, current and historical data for <i>Imperfect Lovers</i> &#8212; including dates, 
              times, gifts, and balances with each trans-action &#8212; are available through a free and public API.
            
              Stern is currently working with curators, <a href="https://linktr.ee/haiverart">Haiver</a> and <a href="https://linktr.ee/speciesofvalue">Elisabeth Sweet</a>, on an invitational exhitbition,
              where international artists are being asked to make their own interfaces for The Lovers &#8212; be they
              material or virtual, on-chain or otherwise. Stern will work with these artists on getting all the "Lover Data" they need for their versionings, whether that is purely historical, dynamic, and/or ongoing for their art.
            </p>

            <p>
              More information is forthcoming, but if you are interested in participating in or exhibiting 
              a group show of Lovers, please reach out to <a href="https://linktr.ee/haiverart">Haiver</a>.
            </p>

            <img src="./images/greta.JPG" width="100%" />

            
       
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default CuratorsStatement;
