import Web3 from "web3";
import React, { useEffect } from "react";

let web3;

const detectAndSetWeb3 = async () => {
  if (!window.ethereum){web3 = new Web3(
    "https://mainnet.infura.io/v3/24eefa63dbae4b7c9f94faccaea3b257"
  );} else {
  const connect = await window.ethereum.request({ method: "eth_accounts" });
  const connected = connect != 0;
  if (window.ethereum && connected) {
    // MetaMask is installed
    try {
      // Request account access if needed
      await window.ethereum.request({ method: "eth_requestAccounts" });
      // Create Web3 instance with MetaMask provider
      web3 = new Web3(window.ethereum);

      //  console.log('metamask connected')

      const networkId = await window.ethereum.request({
        method: "eth_chainId",
      });

      if (networkId !== "0x1") {
        // Replace "0xaa36a7" with the desired network's chainId 0x1
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }], // Replace "0xaa36a7" with the desired network's chainId 0x1
        });
      }

      web3 = new Web3(window.ethereum);
    } catch (error) {
      console.error("User denied account access");
    }
  } else {
    web3 = new Web3(
      "https://mainnet.infura.io/v3/24eefa63dbae4b7c9f94faccaea3b257"
    );
    // console.log('infura connected ')
  } }

  return web3;
};

const Webconnection = () => {
  useEffect(() => {
    // Add event listener for 'accountsChanged'
    const accountsChangedHandler = () => {
      detectAndSetWeb3();
    };

    if (window.ethereum){
    window.ethereum.on("accountsChanged", accountsChangedHandler);

    // Return cleanup function to remove event listener
    return () => {
      // Remove the 'accountsChanged' event listener
      window.ethereum.removeListener("accountsChanged", accountsChangedHandler);
    };}
  }, []);

  // Render nothing because this component doesn't have any UI
  return null;
};

const initMetaMask = async () => {
  if (typeof window.ethereum !== "undefined") {
    // Use MetaMask provider

    web3 = new Web3(window.ethereum);

    const networkId = await window.ethereum.request({ method: "eth_chainId" });

    if (networkId !== "0x1") {
      // Replace "0xaa36a7" with the desired network's chainId 0x1
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }], // Replace "0xaa36a7" with the desired network's chainId 0x1
      });
    }

    web3 = new Web3(window.ethereum);

    // Request account access if needed
    await window.ethereum
      .request({ method: "eth_requestAccounts" })
      .catch((error) => {
        // User denied account access...
        console.error("User denied account access:", error);
        throw error;
      });
  } else {
    throw new Error("MetaMask is not installed.");
  }
};

// Function to get web3 instance
const getWebcall = async () => {
  // const accounts = await web3.eth.getAccounts();

  await initMetaMask();

  return web3;
};

export { getWebcall };

export default Webconnection;
export { web3 };
export { detectAndSetWeb3 };
